<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="[dataUsagePercentageSeries]"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import moment from '@/libs/moment'
import DataDomainChartMixin from './DataDomainChartMixin'

export default {
  mixins: [DataDomainChartMixin],
  data() {
    return {
      isRendered: false,
      requestIncludedFields: [
        'UsedInPercent',
      ],
      chartOptions: {
        chart: {
          id: 'dataUsagePercentageChart',
          group: this.$props.chartGroup,
          type: 'line',
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          show: true,
          curve: 'straight',
          lineCap: 'butt',
          width: 1.5,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'Data usage %',
          align: 'left',
        },
        yaxis: [
          {
            seriesName: 'Data usage',
            labels: {
              minWidth: 0,
              formatter(val) {
                return `${(val).toFixed(0)}%`
              },
            },
            title: {
              text: 'Percentage %',
            },
            min: 0,
            max: 100,
            minWidth: 0,
          },
        ],
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(val).toFixed(0)}%`
              }

              return null
            },
          },
          x: {
            formatter(val) {
              return moment.utc(val).format('L')
            },
          },
        },
      },
    }
  },
  computed: {
    dataUsagePercentageSeries() {
      return {
        name: 'Data usage',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.usedInPercent]),
      }
    },
  },
}

</script>
